import React, { FunctionComponent } from "react"
import "../components/layout.css"
import styled, { createGlobalStyle } from "styled-components";
import pressImage from "../images/javla-liv-cover-full-size.jpg"

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh !important;
  overflow: scroll !important;
`;

const Container = styled.div`
  width: 50%;
  background: white;
  min-height: 100vh !important;
  overflow: auto !important;
  padding: 60px;
  margin: 60px 0;

  @media screen and (max-width: 1020px) { 
    width: 85%;
    padding: 40px;
    margin: 60px 0;
  }
  @media screen and (max-width: 750px) { 
    width: 90%;
    padding: 30px;
    margin: 30px 0;
  }

`;

const PressImage = styled.img.attrs(() =>({
  src: pressImage
}))`
`

const Presseskriv: FunctionComponent = () => {

  return (
    <div className="layout">
      <OuterContainer>
        <Container>

          <h1 id="Presseskriv">Presseskriv </h1>

          <h3 id="Akersborg">Akersborg</h3>

          <p>Akersborg lager musikk som føles ut som å sette seg i en berg-og-dalbanevogn fra 1920-tallet i det den setter utfor kanten og suser inn i en neontunell fylt med skrikevokal, overraskelser, forløsende refrenger, catchy gitarriff, galskap, kreativitet og estetikk.  </p>

          <p>Bak prosjektet står Dag von Krogh Bunkholt med hjelp av musikere som Tobias Ørnes (Aming For Enrike, Shining, Ishan), Agnete Kjølsrud (Animal Alpha, Djerv), Maciek Ofstad (Kvelertak, The Needs), Uggi Mane (Beaten To Death, She Said Destroy), og Togga Fresh! </p>
          <p>Debutalbumet «Feelantropicoco» lanseres mot sommeren, men frem til det vil det gis ut en rekke singler. Først ut er «Et jävla liv vi lever» som lanseres digitalt 21.01.22. </p>

          <h3 id="Et jävla liv vi lever">Et jävla liv vi lever</h3>
          <p>Låten ble til etter at Dag sin gode venn Simon Ström leste opp en tekst han hadde skrevet for et standup show. På den tiden holdt Dag på å skrive debutalbumet til Akersborg og hadde en forferdelig kreativ tørkeperiode. Dag tenkte at han måtte prøve å gjøre noe  annerledes så han bestemte seg for å lage en låt med denne teksten som utgangspunkt.</p>
          <p>Resultatet ble «Et jävla liv vi lever». En ektefølt og poetisk tekst om <i>“längtan efter att leva på riktigt”</i> på Simon Ström sitt morsmål svensk, akkompagnert av Akersborg sitt hardcore univers.  </p>


          <h3> Høyoppløselig bilde </h3>
          <PressImage />
        </Container>
      </OuterContainer>
      </div>
    ) 
  }

export default Presseskriv;